<template>
	<div id="QuizUpload" v-loading="loading" :element-loading-text="loadingText" element-loading-background="rgba(0, 0, 0, 0)">
		<div class="downFileStyle">
			<el-button size="small" type="warning"  @click="downFile">获取试题上传模板</el-button>
			<hr>
		</div>
		<!-- 上传文件 :on-remove="hanleRemove" :on-change="handleChange" -->
		<el-form :model="form" ref="form" :rules="formRules" class="demo-ruleForm">
			<el-form-item label=" " ref="file" prop="file" label-width="0px">
				<!--  :http-request="httpRequest" -->
				<el-upload class="myUpload" ref='upload' v-model="form.file" drag action :file-list="fileList"
					:before-upload="beforeUpload" :http-request="httpRequest" :on-change="handleChange">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将试题excel文件拖到此处，或<em>点击上传</em></div>
					<div slot="tip" class="el-upload__tip" v-show="myTipShow">支持xlsx格式，且文件名&le;50位，文件大小&lt;10MB</div>
				</el-upload>
			</el-form-item>
			<el-form-item label="选择考试范围" label-width="110px">
				<!-- :filter-method="selectFilter" @visible-change="selectVisible" -->
				<el-select v-model="form.scope" filterable collapse-tags allow-create placeholder="请选择考试范围"
					clearable ref="select">
					<el-option v-for="(item,index) in scopeOptions" :key="index" :label="item.scope" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button size="small" type="primary" @click="onSubmit('form')">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import $ from 'jquery';
	export default {
		name: 'QuizUpload',
		data() {
			return {
				// 上传文件
				form: {
					file: "",
					fileOriginName: "",
					scope: ""
				},
				fd: new FormData(),
				//文件上传
				fileList: [],
				fileOriginPath: "",
				// 文件上传之前错误判断
				fileForm: false,
				fileMax: false,
				fileLength: false,
				loading: false,
				loadingText:"",
				hasFmt: false,
				// 上传文件之前默认提示语
				myTipShow: true,
				// 文件检验
				formRules: {
					file: [{
						validator: this.uploadFile,
						trigger: "change"
					}],
				},
				scopeOptions: [], //考试范围
				// SelectNavTemporary:[],
				// 只能点击一次
				submitProcessing: false,
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			// 获取试题上传模板
			downFile() {
				let _this = this;
				_this.loading = true;
				_this.loadingText = '下载中';
				_this.$axios({
						method: "get",
						url: "/download/quiz/template",
						responseType: "blob"
					}).then(res => {
						if (res.data) {
							let url = window.URL.createObjectURL(new Blob([res.data]));
							let a = document.createElement("a");
							a.style.display = "none";
							a.href = url;
							a.setAttribute(
								"download",
								"试题上传模板.xlsx"
							);
							document.body.appendChild(a);
							a.click();
							$(a).remove();
							_this.loading = false;
							_this.loadingText = '';
							_this.$notify.success({
								message: '模板下载成功！',
								duration: 2000
							});
						} else {
							_this.loading = false;
							_this.loadingText = '';
							_this.$notify.error({
								message: '模板下载失败！',
								duration: 2000
							});
						}
					})
					.catch(error => {
						_this.loading = false;
						_this.loadingText = '';
						_this.$notify.error({
							message: '模板下载失败',
							duration: 2000
						});
						return false;
					})
			},
			// 文件提示语
			uploadFile(rule, value, callback) {
				if (this.hasFmt) {
					callback(new Error("请上传文件"));
				} else if (this.ifDesc || this.fileForm || this.fileMax || this.fileLength) {
					this.myTipShow = false;
					callback(new Error(this.fileMesg));
				} else {
					this.myTipShow = true;
					callback();
				}
			},
			// 文件上传之前
			beforeUpload(file) {
				let that = this;
				// 如果提交表单成功,且有内容,则列表显示
				if (navigator.onLine == true) {
					that.fileMesg = "";
					that.fileForm = false;
					that.fileMax = false;
					that.fileLength = false;
					var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
					// 文件名包括.xls
					var testname = file.name;
					//上传成功之后的赋值显示文件名称
					that.fileOriginPath = file.name;
					const extension2 = testmsg === "xlsx";
					const isLtM = file.size / 1024 / 1024 < 10;
					if (!extension2) {
						that.fileForm = true;
						that.fileList = [];
						that.fileMesg = "不正确的文件格式";
						that.$refs.form.validateField("file", valid => {});
						return false;
					}
					if (!isLtM) {
						that.fileMax = true;
						that.fileList = [];
						that.fileMesg = "上传内容需小于10M";
						that.$refs.form.validateField("file", valid => {});
						return false;
					}
					if (testname.length > 50) {
						that.fileLength = true;
						that.fileList = [];
						that.fileMesg = "文件的文件名长度不能超过50位";
						that.$refs.form.validateField("file", valid => {});
						return false;
					}
				} else {
					that.fileList = [];
				}
			},
			//上传文件接口
			httpRequest(param) {
				let _that = this;
				_that.formData=null;
				_that.fd=new FormData();
				if (param.file.name) {
					// 判断上传文件是否报错
					if (_that.fileForm || _that.fileMax || _that.fileLength) {} else {
						_that.hasFmt = false;
						_that.ifDesc = false;
						_that.fileMesg = "";
						_that.fd.append("file", param.file);
					}
				}
			},
			// 获取考试范围数据
			getData() {
				let self = this;
				self.$axios.post("/quizScope/list", {
					keyword: ""
				}).then(function(res) {
					self.scopeOptions = res.data;
					// self.SelectNavTemporary = res.data;
				}).catch(function(error) {});
			},
			// 考试范围过滤方法
			// selectFilter(val) {
			// 	var that = this;
			// 	if (val) {
			// 		that.scopeOptions = that.SelectNavTemporary.filter((item) => {
			// 			if (!!~item.scope.indexOf(val) || !!~item.scope.toUpperCase().indexOf(val.toUpperCase())) {
			// 				return true
			// 			}
			// 		})
			// 	} else {
			// 		//赋值还原
			// 		that.scopeOptions = that.SelectNavTemporary;
			// 	}
			// },
			// selectVisible(flag) {
			// 	if (flag) {
			// 		this.scopeOptions = this.SelectNavTemporary;
			// 	} else {}
			// },
			// 提交
			onSubmit(formName) {
				var that = this;
				that.loading = true;
				if (that.submitProcessing) {
					return;
				}
				that.submitProcessing = true;
				that.fd.delete("scope");
				that.fd.delete("scopeId");
				// 判断文件上传前是否再次修改
				// that.file.slice( 0, 1 ) 
				//   .arrayBuffer()
				//   .then( () => {
				//     console.log( 'should be fine' );
				//   } )
				//   .catch( (err) => {
				//     console.log( 'failed to read' );
				//     that.file = null; // 把缓存的file清空
				// } );			
				that.$refs[formName].validate((valid) => {
					if (valid) {
						if((typeof that.form.scope)=='string'){
							that.fd.append("scope", that.form.scope);
							that.fd.append("scopeId", "");
						}else{
							that.fd.append("scope", "");
							that.fd.append("scopeId", that.form.scope);
						}
						that.$axios
							.post("/quiz/upload", that.fd)
							.then(function(res) {
								that.loading = false;
								that.submitProcessing = false;
								that.myTipShow = false;
								that.ifDesc = false;
								that.fileMesg = "";
								that.$notify({
									title: '提示',
									message: '试题上传成功!',
									type: 'success',
									duration: 2000
								});
								that.$router.push('/quizList');
							})
							.catch(function(error) {
								that.$notify({
									title: '失败',
									message: error,
									type: 'error',
									duration: 2000
								});
								that.loading = false;
								that.submitProcessing = false;
								return false;
							});
					} else {
						return false;
					}
				})
			},
			// 覆盖上传
			handleChange(file, fileList) {
				let _this = this;
				_this.hasFmt = false;
				if (fileList.length > 0) {
					// $(".mytip").addClass("tipBtn");
					_this.fileList = [fileList[fileList.length - 1]];
					_this.$refs["form"].clearValidate("file");
				}
			},
		}
	}
</script>

<style lang='scss' scoped>
	#QuizUpload {
		max-width: 1000px;
		margin: 0 auto;
		background: #FFFFFF;
		padding: 15px;
		
		.downFileStyle{
			text-align: right;
			margin-bottom: 20px;
		}
		.demo-ruleForm {
			text-align: left;

			/* 上传按钮 */
			.myUpload {
				position: relative;

				/* 上传按钮提示语 */
				.el-upload__tip {
					color: #f4ae47;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-top: -10px;
					line-height: 40px;
				}
			}

			.el-select {
				width: 260px;
			}
		}
	}
</style>
